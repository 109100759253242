













































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import EmailRichTextField from '@/components/common/EmailRichTextField.vue'
import { EmailType, EmailRecipient, EmailerRoleOptions, EmailerSubActiveOptions, EmailerSubTypeOptions, EmailerSubGroupOptions, EmailerWondeStatusOptions, EmailerPriceTypeOptions } from '@/edshed-common/api/types/email'
import { Api, request } from '@/edshed-common/api'

@Component({
  components: {
    EmailRichTextField
  }
})
export default class EmailsView extends Vue {
  roleMe: boolean = true
  roleUsers: boolean = false
      roleTeachers: boolean = false
      roleAdmins: boolean = false
      roleSuperusers: boolean = false
      subscriptionActive: boolean = true
      subscriptionExpired: boolean = false
      subscriptionNone: boolean = false
      subscriptionFree: boolean = false
      subscriptionPremium: boolean = true
      subscriptionEdShed: boolean = true
      subscriptionLitShed: boolean = true
      wondeLinked: boolean = true
      wondeUnlinked: boolean = true
      fixedSubs: boolean = false
      meteredSubs: boolean = false
      subject: string = ''
      message: string = ''
      all: boolean = false
      showPreview: boolean = false
      showRecipients: boolean = false
      previewContent: string = ''
      originalMessage: string = ''
      originalSubject: string = ''
      originalRoleMe: boolean = true
      originalRoleUsers: boolean = false
      originalRoleTeachers: boolean = false
      originalRoleAdmins: boolean = false
      originalRoleSuperusers: boolean = false
      originalSubscriptionActive: boolean = true
      originalSubscriptionExpired: boolean = false
      originalSubscriptionNone: boolean = false
      originalSubscriptionFree: boolean = false
      originalSubscriptionPremium: boolean = true
      originalSubscriptionEdShed: boolean = true
      originalSubscriptionLitShed: boolean = true
      originalWondeLinked: boolean = true
      originalWondeUnlinked: boolean = true
      originalFixedSubs: boolean = true
      originalMeteredSubs: boolean = true
      originalAll: boolean = false
      originalType: EmailType = 'marketing'
      originalSelectedCountries: Array<any> = []
      emailId: number = 0
      type: EmailType = 'marketing'
      recipientData: Array<EmailRecipient> = []
      recipientDataCount: number = 0
      countriesData: Array<any> = []
      filteredCountries: Array<any> = []
      selectedCountries: Array<any> = []
      priceTypes: Array<any> = []
      emailTypes: Array<EmailType> = EmailType.flat()
      isSendingEmail: boolean = false

      get formattedEmailHTML () {
        let html = encodeURI(this.previewContent)
        html = html.replace(/#/g, '%23')
        return html
      }

      get isEdit () {
        return this.emailId > 0
      }

      get hasChanged () {
        if (this.message !== this.originalMessage) {
          return true
        }

        if (this.subject !== this.originalSubject) {
          return true
        }

        if (this.roleMe !== this.originalRoleMe) {
          return true
        }

        if (this.roleUsers !== this.originalRoleUsers) {
          return true
        }

        if (this.roleTeachers !== this.originalRoleTeachers) {
          return true
        }

        if (this.roleAdmins !== this.originalRoleAdmins) {
          return true
        }

        if (this.roleSuperusers !== this.originalRoleSuperusers) {
          return true
        }

        if (this.all !== this.originalAll) {
          return true
        }

        if (this.type !== this.originalType) {
          return true
        }

        if (this.subscriptionActive !== this.originalSubscriptionActive) {
          return true
        }

        if (this.subscriptionExpired !== this.originalSubscriptionExpired) {
          return true
        }

        if (this.subscriptionNone !== this.originalSubscriptionNone) {
          return true
        }

        if (this.subscriptionFree !== this.originalSubscriptionFree) {
          return true
        }

        if (this.subscriptionPremium !== this.originalSubscriptionPremium) {
          return true
        }

        if (this.subscriptionEdShed !== this.originalSubscriptionEdShed) {
          return true
        }

        if (this.subscriptionLitShed !== this.originalSubscriptionLitShed) {
          return true
        }

        if (this.wondeLinked !== this.originalWondeLinked) {
          return true
        }

        if (this.wondeUnlinked !== this.originalWondeUnlinked) {
          return true
        }

        if (this.fixedSubs !== this.originalFixedSubs) {
          return true
        }

        if (this.meteredSubs !== this.originalMeteredSubs) {
          return true
        }

        const that = this

        const selectedCodes = this.selectedCountries.map(country => country.code)
        const originalSelectedCodes = this.originalSelectedCountries.map(country => country.code)

        if (selectedCodes.length !== originalSelectedCodes.length) {
          return true
        }

        if (!selectedCodes.every(code => originalSelectedCodes.includes(code))) {
          return true
        }

        return false
      }

      mounted () {
        if (this.$route.params.id !== undefined) {
          try {
            this.emailId = parseInt(this.$route.params.id)
          } catch (err) {
            this.emailId = 0 /// revert to add email screen
          }
        }

        if (!this.$store.state.user.superuser) {
          this.$router.push('/noaccess')
        }

        this.loadCountries()

        if (this.isEdit) {
          request('GET', 'email/' + this.emailId, null)
            .then((response) => {
              console.log(response)
              if (!response || !response.data) {
                return
              }

              const email = response.data.email

              this.subject = email.subject
              this.message = email.body
              this.roleMe = email.recipient_query.roles.includes('me')
              this.roleTeachers = email.recipient_query.roles.includes('teachers')
              this.roleAdmins = email.recipient_query.roles.includes('admins')
              this.roleUsers = email.recipient_query.roles.includes('users')
              this.roleSuperusers = email.recipient_query.roles.includes('superusers')
              this.subscriptionActive = email.recipient_query.actives.includes('active')
              this.subscriptionExpired = email.recipient_query.actives.includes('expired')
              this.subscriptionNone = email.recipient_query.types.includes('none')
              this.subscriptionFree = email.recipient_query.types.includes('free')
              this.subscriptionPremium = email.recipient_query.types.includes('premium')
              this.subscriptionEdShed = email.recipient_query.groups.includes('edshed')
              this.subscriptionLitShed = email.recipient_query.groups.includes('litshed')
              this.wondeLinked = email.recipient_query.wonde.includes('wondeLinked')
              this.wondeUnlinked = email.recipient_query.wonde.includes('wondeUnlinked')
              this.fixedSubs = email.recipient_query.price_types.includes('fixed')
              this.meteredSubs = email.recipient_query.price_types.includes('metered')
              this.selectedCountries = this.countriesData.filter(country => response.data.email.recipient_query.countries.includes(country.code))
              this.all = email.recipient_query.ignore_marketing
              this.type = email.type

              this.originalMessage = this.message
              this.originalSubject = this.subject
              this.originalRoleMe = this.roleMe
              this.originalRoleTeachers = this.roleTeachers
              this.originalRoleAdmins = this.roleAdmins
              this.originalRoleUsers = this.roleUsers
              this.originalRoleSuperusers = this.roleSuperusers
              this.originalSubscriptionActive = this.subscriptionActive
              this.originalSubscriptionExpired = this.subscriptionExpired
              this.originalSubscriptionNone = this.subscriptionNone
              this.originalSubscriptionFree = this.subscriptionFree
              this.originalSubscriptionPremium = this.subscriptionPremium
              this.originalSubscriptionEdShed = this.subscriptionEdShed
              this.originalSubscriptionLitShed = this.subscriptionLitShed
              this.originalWondeLinked = this.wondeLinked
              this.originalWondeUnlinked = this.wondeUnlinked
              this.originalFixedSubs = this.fixedSubs
              this.originalMeteredSubs = this.meteredSubs
              this.originalSelectedCountries = [...this.selectedCountries]
              this.originalAll = this.all
              this.originalType = this.type
            })
            .catch((error) => {
              console.log(error)

              if (error.response.status === 403) {
                console.log('FORBIDDEN')
                this.$router.push('/logout')
              }

              this.$buefy.toast.open({
                duration: 5000,
                message: 'Could not load email',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      }

      previewEmail () {
        request('POST', 'email/preview', { message: this.message })
          .then((response) => {
            this.previewContent = response.data.data
            this.showPreview = true
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.$buefy.toast.open({
              duration: 5000,
              message: 'Could not generate preview',
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      }

      getRecipients () {
        request('GET', 'email/' + this.emailId + '/recipients', {})
          .then((response) => {
            this.recipientData = response.data.sample
            this.recipientDataCount = response.data.count
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.$buefy.toast.open({
              duration: 5000,
              message: 'Could not load recipients',
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      }

      saveClicked () {
        if (!this.validateData()) {
          return
        }

        if (this.isEdit) {
          this.updateEmail()
        } else {
          this.addEmail()
        }
      }

      validateData () {
        if (!this.roleTeachers && !this.roleAdmins && !this.roleSuperusers && !this.roleUsers && !this.roleMe) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Please select at least one role',
            position: 'is-bottom',
            type: 'is-danger'
          })

          return false
        }

        if (!this.subscriptionActive && !this.subscriptionExpired) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Please select at least one subscription status',
            position: 'is-bottom',
            type: 'is-danger'
          })

          return false
        }

        if (!this.subscriptionFree && !this.subscriptionPremium && !this.subscriptionNone) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Please select at least one subscription type',
            position: 'is-bottom',
            type: 'is-danger'
          })

          return false
        }

        if (!this.subscriptionEdShed && !this.subscriptionLitShed) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Please select at least one subscription group',
            position: 'is-bottom',
            type: 'is-danger'
          })

          return false
        }

        if (!this.wondeLinked && !this.wondeUnlinked) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Please select at least one Wonde Status',
            position: 'is-bottom',
            type: 'is-danger'
          })

          return false
        }

        return true
      }

      addEmail () {
        request('POST', 'email', { roles: this.getSelectedRoles(), actives: this.getSelectedActives(), types: this.getSelectedTypes(), groups: this.getSelectedGroups(), wonde: this.getSelectedWondeStatuses(), price_types: this.getSelectedPriceTypes(), countries: this.getSelectedCountries(), subject: this.subject, body: this.message, ignore_marketing: this.all, type: this.type })
          .then((response) => {
            this.$buefy.toast.open({
              message: 'Email saved',
              type: 'is-success'
            })

            this.$router.push('/emails/' + response.data.email.id)
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.$buefy.toast.open({
              duration: 5000,
              message: 'Could not create email',
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      }

      updateEmail () {
        request('PUT', 'email/' + this.emailId, { roles: this.getSelectedRoles(), actives: this.getSelectedActives(), types: this.getSelectedTypes(), groups: this.getSelectedGroups(), wonde: this.getSelectedWondeStatuses(), price_types: this.getSelectedPriceTypes(), countries: this.getSelectedCountries(), subject: this.subject, body: this.message, ignore_marketing: this.all, type: this.type })
          .then((response) => {
            this.$buefy.toast.open({
              message: 'Email saved',
              type: 'is-success'
            })

            this.originalMessage = response.data.email.body
            this.originalSubject = response.data.email.subject
            this.originalRoleMe = response.data.email.recipient_query.roles.includes('me')
            this.originalRoleTeachers = response.data.email.recipient_query.roles.includes('teachers')
            this.originalRoleAdmins = response.data.email.recipient_query.roles.includes('admins')
            this.originalRoleSuperusers = response.data.email.recipient_query.roles.includes('superusers')
            this.originalRoleUsers = response.data.email.recipient_query.roles.includes('users')
            this.originalSubscriptionActive = response.data.email.recipient_query.actives.includes('active')
            this.originalSubscriptionExpired = response.data.email.recipient_query.actives.includes('expired')
            this.originalSubscriptionNone = response.data.email.recipient_query.types.includes('none')
            this.originalSubscriptionFree = response.data.email.recipient_query.types.includes('free')
            this.originalSubscriptionPremium = response.data.email.recipient_query.types.includes('premium')
            this.originalSubscriptionEdShed = response.data.email.recipient_query.groups.includes('edshed')
            this.originalSubscriptionLitShed = response.data.email.recipient_query.groups.includes('litshed')
            this.originalWondeLinked = response.data.email.recipient_query.wonde.includes('wondeLinked')
            this.originalWondeUnlinked = response.data.email.recipient_query.wonde.includes('wondeUnlinked')
            this.originalFixedSubs = response.data.email.recipient_query.price_types.includes('fixed')
            this.originalMeteredSubs = response.data.email.recipient_query.price_types.includes('metered')

            const desiredCountries = this.countriesData.filter(country => response.data.email.recipient_query.countries.includes(country.code))

            this.originalSelectedCountries = desiredCountries
            this.originalAll = response.data.email.recipient_query.ignore_marketing
            this.originalType = response.data.email.type
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.$buefy.toast.open({
              duration: 5000,
              message: 'Could not save email',
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      }

      loadCountries () {
        this.$store.commit('TOGGLE_LOADING')
        // var that = this
        request('GET', 'countries', null)
          .then((response) => {
            const data = response.data
            this.countriesData = data.countries
            this.filteredCountries = this.countriesData

            if (!this.countriesData || this.countriesData.length === 0) {
              throw new Error('Could not load country data')
            }
            this.$store.commit('TOGGLE_LOADING')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')

            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.$buefy.toast.open({
              duration: 5000,
              message: 'Could not load country data',
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      }

      getFilteredCountries (text) {
        this.filteredCountries = this.countriesData.filter((option) => {
          return option.name
            .toLowerCase()
            .includes(text.toLowerCase())
        })
      }

      sendClicked () {
        if (this.hasChanged) {
          this.$buefy.dialog.confirm({
            title: 'Email Changed',
            message: `This email has been changed and needs to be saved 
                    before sending.`,
            cancelText: 'Cancel',
            confirmText: 'Save',
            type: 'is-success',
            onConfirm: () => this.saveClicked()
          })
        } else {
        // show recipient preview
          this.showRecipients = true

          this.getRecipients()
        }
      }

      async sendEmail () {
        try {
          this.isSendingEmail = true
          const jobId = await Api.sendEmail(this.emailId)
          this.$buefy.toast.open({
            message: 'Email sending',
            type: 'is-success',
            position: 'is-bottom'
          })
          this.showRecipients = false
          this.$router.push(`/emails?job=${jobId}`)
        } catch (err) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Could not send email',
            position: 'is-bottom',
            type: 'is-danger',
            container: '.sendEmailModal'
          })
        } finally {
          this.isSendingEmail = false
        }
      }

      getSelectedRoles () {
        const roles: Array<EmailerRoleOptions> = []

        if (this.roleMe) {
          roles.push('me')
        }

        if (this.roleUsers) {
          roles.push('users')
        }

        if (this.roleSuperusers) {
          roles.push('superusers')
        }

        if (this.roleAdmins) {
          roles.push('admins')
        }

        if (this.roleTeachers) {
          roles.push('teachers')
        }

        return roles
      }

      getSelectedActives () {
        const actives: Array<EmailerSubActiveOptions> = []

        if (this.subscriptionActive) {
          actives.push('active')
        }

        if (this.subscriptionExpired) {
          actives.push('expired')
        }

        return actives
      }

      getSelectedPriceTypes () {
        const priceTypes: Array<EmailerPriceTypeOptions> = []

        if (this.fixedSubs) {
          priceTypes.push('fixed')
        }

        if (this.meteredSubs) {
          priceTypes.push('metered')
        }

        return priceTypes
      }

      getSelectedTypes () {
        const types: Array<EmailerSubTypeOptions> = []

        if (this.subscriptionFree) {
          types.push('free')
        }

        if (this.subscriptionPremium) {
          types.push('premium')
        }

        if (this.subscriptionNone) {
          types.push('none')
        }

        return types
      }

      getSelectedGroups () {
        const groups: Array<EmailerSubGroupOptions> = []

        if (this.subscriptionEdShed) {
          groups.push('edshed')
        }

        if (this.subscriptionLitShed) {
          groups.push('litshed')
        }

        return groups
      }

      getSelectedWondeStatuses () {
        const statuses: Array<EmailerWondeStatusOptions> = []

        if (this.wondeLinked) {
          statuses.push('wondeLinked')
        }

        if (this.wondeUnlinked) {
          statuses.push('wondeUnlinked')
        }

        return statuses
      }

      getSelectedCountries () {
        return this.selectedCountries.map(country => country.code)
      }
}
